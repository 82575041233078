<script setup>
import { WdsIcon, WdsPopup } from '@wds/components';

// ### COMPOSABLES
const { isExtraSmallScreen } = useMq();

// ### STORES
const sustainabilityStore = useSustainabilityStore();

const getSustainabilityInfo = computed(() => {
  const sustainabilityDetails = toValue(sustainabilityStore.getCurrentSustainabilityDetails);
  return {
    tier: (sustainabilityDetails?.tier === '4' ? '3-plus' : sustainabilityDetails?.tier) || null,
    title: sustainabilityDetails?.title || '',
    description: sustainabilityDetails?.description || '',
  };
});
</script>

<template>
  <WdsPopup
    v-if="getSustainabilityInfo.tier"
    class="sustainability-popup"
    :is-open="sustainabilityStore.isSustainabilityPopupOpen"
    :has-header="false"
    :apply-sheet="isExtraSmallScreen"
    :sheet-height="0"
    :close-button="true"
    @popup-close="sustainabilityStore.toggleSustainabilityPopup(false)"
  >
    <template #popupContent>
      <div class="sustainability-details">
        <WdsIcon
          :asset="`sustainability-${getSustainabilityInfo.tier}`"
          height="40"
          width="40"
          color="green"
        />
        <span class="sustainability-title">{{ getSustainabilityInfo.title }}</span>
        <span class="sustainability-description">{{ getSustainabilityInfo.description }}</span>
      </div>
    </template>
  </WdsPopup>
</template>

<style scoped lang="scss">
.sustainability-details {
  display: flex;
  flex-direction: column;
  gap: $wds-spacing-m;
  align-items: center;
  padding: $wds-spacing-m;
  max-width: wds-rem(550px);

  .sustainability-title {
    @include title-5-bld;
  }

  .sustainability-description {
    @include body-1-reg;

    text-align: center;
  }

  @include tablet {
    padding: $wds-spacing-l 0;
  }
}
</style>
